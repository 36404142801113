var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getLayoutWSD.wsd === true)?_c('v-container',{staticClass:"dashboard-WSD pa-0"},[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":"","justify":"center"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","position":"absolute","opacity":"0.2"},attrs:{"src":require("@/assets/img/wsd/bg_dashboard.jpg"),"alt":""}}),_c('v-col',{staticClass:"login__image dashboard-WSD__content hidden-sm-and-down",attrs:{"cols":"12","md":"8","lg":"9","sm":"7"}},[_c('div',{staticClass:"dashboard-WSD__logo"},[_c('img',{attrs:{"src":require("@/assets/img/wsd/logowsd.png"),"alt":"Logo WSD"}})]),_c('div',{staticClass:"p-relative"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/wsd/dziecko.jpg"),"alt":"Hero image"}}),_c('p',{staticClass:"p-absolute d-flex flex-wrap justify-center dashboard-WSD__text"},[_c('span',{staticClass:"py-5",staticStyle:{"font-weight":"700"}},[_vm._v("MISJA SZPITALA")]),_vm._v(" Misją Szpitala jest zapewnienie nowoczesnej diagnostyki i kompleksowego, interdyscyplinarnego leczenia chorób wieku dziecięcego z zastosowaniem innowacyjnych technologii medycznych oraz prowadzenie profilaktyki i edukacji zdrowotnej. ")])])]),_c('v-col',{attrs:{"cols":"12","lg":"3","md":"5","sm":"5"}},[_c('v-card',{staticClass:"elevation-20 mr-5",staticStyle:{"height":"calc(100vh - 64px)","padding":"15px","background-color":"rgba(255, 255, 255, 0.02)","overflow":"auto"}},[_c('p',{staticClass:"pa-5 ma-0",staticStyle:{"text-align":"center","width":"100%","font-size":"23px","letter-spacing":"2px"}},[_vm._v(" Nadchodzące wydarzenia "),_c('span',{staticStyle:{"display":"block","width":"100%","border-bottom":"1px solid black","margin-top":"15px"}})]),_c('div',_vm._l((_vm.getUpcomingEvents),function(info){return _c('div',{key:info.index},[(
                info.homePage == true &&
                  info.status.possibleStatus.name == 'Aktualny'
              )?_c('v-alert',{key:info.id,staticClass:"pa-5",staticStyle:{"margin-right":"2px"},attrs:{"border":"left","colored-border":"","color":info.priority ? info.priority.background : 'grey lighten-1',"elevation":"2"}},[_c('div',{staticClass:"m2-4",staticStyle:{"color":"#674ea7","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$moment(info.createdAt).format('YYYY-MM-DD HH:mm'))+" ")]),_c('div',{staticClass:"mb-2",staticStyle:{"padding":"0","color":"#674ea7","font-weight":"500"}},[_vm._v(" "+_vm._s(info.title)+" ")]),_c('span',{staticStyle:{"height":"1px","width":"100%","background-color":"lightgrey","display":"block","margin-bottom":"5px"}}),_c('div',{staticClass:"tasks__content",staticStyle:{"padding":"10px 0"}},[_c('p',{staticClass:"ma-0 mb-5",staticStyle:{"color":"rgba(0, 0, 0, 0.6)"}},[_vm._v(" "+_vm._s(info.information)+" ")])]),_c('div',{staticStyle:{"position":"absolute","bottom":"15px","color":"grey"}},[_c('span',{staticClass:"d-flex align-center",staticStyle:{"font-size":"11px !important"}},[_c('v-icon',{staticStyle:{"margin-bottom":"2px"},attrs:{"size":"11"}},[_vm._v("mdi-account")]),_c('p',{staticClass:"my-0 mx-1"},[_vm._v(" "+_vm._s(info.createdBy.name)+" "+_vm._s(info.createdBy.lastname)+" ")])],1)]),_c('div',{staticClass:"tasks__buttons"},[(info.folders)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"width":"25px","height":"25px"},attrs:{"fab":"","dark":"","href":_vm.paths.attachments + info.folders.fakeFileName,"target":"_blank","color":"#19aa8d"}},on),[_c('v-icon',{attrs:{"size":"12","color":"white"}},[_vm._v("mdi-file-search")])],1)]}}],null,true)},[_c('span',[_vm._v("Podgląd")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(_vm.getPermission.level >= 3)?_c('v-btn',_vm._g({staticStyle:{"width":"25px","height":"25px"},attrs:{"fab":"","dark":"","color":"#674ea7"},on:{"click":function($event){("" + (_vm.$router.push('/current-informations/' + info._id)))}}},on),[_c('v-icon',{attrs:{"size":"15","color":"white"}},[_vm._v("mdi-arrow-right-thick")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Przejdź do szczegółów")])])],1)]):_vm._e()],1)}),0)])],1)],1)],1):_c('v-container',{staticClass:"dashboard"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}})],1),_c('div',{staticStyle:{"position":"absolute"}},[_c('img',{staticStyle:{"transform":"translate(0%,-50%)"},style:((" display: " + (_vm.window.width < 1200 ? 'none' : 'block'))),attrs:{"src":require("@/assets/img/global/entelli_logo.png"),"alt":""}})]),_c('div',{staticClass:"dashboard_logo_subtitle",style:((" display: " + (_vm.window.width < 1200 ? 'none' : 'block')))},[_vm._v(" information driven software ")]),(_vm.getUpcomingEvents.length > 0)?_c('v-col',{staticClass:"p-absolute pa-0",staticStyle:{"min-width":"350px","right":"30px"},attrs:{"cols":"12","lg":"3","md":"5","sm":"5"}},[_c('v-card',{staticClass:"elevation-20 mr-5",staticStyle:{"height":"calc(100vh - 64px)","padding":"15px","background-color":"rgba(255, 255, 255, 0.02)","overflow":"auto"}},[_c('p',{staticClass:"pa-5 ma-0",staticStyle:{"text-align":"center","width":"100%","font-size":"23px","letter-spacing":"2px"}},[_vm._v(" Informacje "),_c('span',{staticStyle:{"display":"block","width":"100%","border-bottom":"1px solid black","margin-top":"15px"}})]),_c('div',_vm._l((_vm.getUpcomingEvents),function(info){return _c('div',{key:info.index},[(
              info.homePage == true &&
                info.status.possibleStatus.name == 'Aktualny'
            )?_c('v-alert',{key:info.id,staticClass:"pa-5",staticStyle:{"margin-right":"2px"},attrs:{"border":"left","colored-border":"","color":info.priority ? info.priority.background : 'grey lighten-1',"elevation":"2"}},[_vm._v(" "+_vm._s(info.length)+" "),_c('div',{staticClass:"m2-4",staticStyle:{"color":"#674ea7","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$moment(info.createdAt).format('YYYY-MM-DD HH:mm'))+" ")]),_c('div',{staticClass:"mb-2",staticStyle:{"padding":"0","color":"#674ea7","font-weight":"500"}},[_vm._v(" "+_vm._s(info.title)+" ")]),_c('span',{staticStyle:{"height":"1px","width":"100%","background-color":"lightgrey","display":"block","margin-bottom":"5px"}}),_c('div',{staticClass:"tasks__content",staticStyle:{"padding":"10px 0"}},[_c('p',{staticClass:"ma-0 mb-5",staticStyle:{"color":"rgba(0, 0, 0, 0.6)"}},[_vm._v(" "+_vm._s(info.information)+" ")])]),_c('div',{staticStyle:{"position":"absolute","bottom":"15px","color":"grey"}},[_c('span',{staticClass:"d-flex align-center",staticStyle:{"font-size":"11px !important"}},[_c('v-icon',{staticStyle:{"margin-bottom":"2px"},attrs:{"size":"11"}},[_vm._v("mdi-account")]),_c('p',{staticClass:"my-0 mx-1"},[_vm._v(" "+_vm._s(info.createdBy.name)+" "+_vm._s(info.createdBy.lastname)+" ")])],1)]),_c('div',{staticClass:"tasks__buttons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"width":"25px","height":"25px"},attrs:{"fab":"","dark":"","color":"#19aa8d"},on:{"click":function($event){return _vm.showFirstAttachments({
                        registryID: info._id,
                        registryName: 'current-informations_details',
                      })}}},on),[_c('v-icon',{attrs:{"size":"12","color":"white"}},[_vm._v("mdi-file-search")])],1)]}}],null,true)},[_c('span',[_vm._v("Podgląd załącznika")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"25px","height":"25px"},attrs:{"fab":"","dark":"","color":"#19aa8d"},on:{"click":function($event){("" + (_vm.$router.push('/current-informations/' + info._id)))}}},on),[_c('v-icon',{attrs:{"size":"15","color":"white"}},[_vm._v("mdi-arrow-right-thick")])],1)]}}],null,true)},[_c('span',[_vm._v("Przejdź do szczegółów")])])],1)]):_vm._e()],1)}),0)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }