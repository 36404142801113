<template>
  <v-container v-if="getLayoutWSD.wsd === true" class="dashboard-WSD pa-0">
    <v-row no-gutters justify="center" class="flex-nowrap">
      <img
        style="width: 100%; height: 100%; position: absolute; opacity: 0.2;"
        src="@/assets/img/wsd/bg_dashboard.jpg"
        alt
      />
      <v-col
        cols="12"
        md="8"
        lg="9"
        sm="7"
        class="login__image dashboard-WSD__content hidden-sm-and-down"
      >
        <div class="dashboard-WSD__logo">
          <img src="@/assets/img/wsd/logowsd.png" alt="Logo WSD" />
        </div>
        <div class="p-relative">
          <img
            style="width: 100%"
            src="@/assets/img/wsd/dziecko.jpg"
            alt="Hero image"
          />
          <p
            class="p-absolute d-flex flex-wrap justify-center dashboard-WSD__text"
          >
            <span style="font-weight: 700" class="py-5">MISJA SZPITALA</span>
            Misją Szpitala jest zapewnienie nowoczesnej diagnostyki i
            kompleksowego, interdyscyplinarnego leczenia chorób wieku
            dziecięcego z zastosowaniem innowacyjnych technologii medycznych
            oraz prowadzenie profilaktyki i edukacji zdrowotnej.
          </p>
        </div>
      </v-col>
      <v-col cols="12" lg="3" md="5" sm="5">
        <v-card
          class="elevation-20 mr-5"
          style="height: calc(100vh - 64px); padding: 15px; background-color: rgba(255, 255, 255, 0.02); overflow: auto;"
        >
          <!-- position: fixed; z-index: 10; width: 350px -->
          <p
            class="pa-5 ma-0"
            style="text-align: center; width: 100%; font-size: 23px; letter-spacing: 2px; "
          >
            Nadchodzące wydarzenia
            <span
              style="display: block; width: 100%; border-bottom: 1px solid black; margin-top: 15px"
            ></span>
          </p>

          <div>
            <div v-for="info in getUpcomingEvents" :key="info.index">
              <v-alert
                border="left"
                style="margin-right: 2px"
                colored-border
                v-if="
                  info.homePage == true &&
                    info.status.possibleStatus.name == 'Aktualny'
                "
                :color="
                  info.priority ? info.priority.background : 'grey lighten-1'
                "
                :key="info.id"
                elevation="2"
                class="pa-5"
              >
                <div style="color: #674ea7; font-size: 14px" class="m2-4">
                  {{ $moment(info.createdAt).format('YYYY-MM-DD HH:mm') }}
                </div>
                <div
                  class="mb-2"
                  style="padding: 0; color: #674ea7; font-weight: 500;"
                >
                  {{ info.title }}
                </div>
                <span
                  style="height: 1px; width: 100%; background-color: lightgrey; display: block; margin-bottom: 5px;"
                ></span>
                <div class="tasks__content" style="padding: 10px 0;">
                  <p class="ma-0 mb-5" style="color: rgba(0, 0, 0, 0.6)">
                    {{ info.information }}
                  </p>
                </div>
                <div style="position: absolute; bottom: 15px; color: grey">
                  <span
                    class="d-flex align-center"
                    style="font-size: 11px !important;"
                  >
                    <v-icon size="11" style="margin-bottom: 2px"
                      >mdi-account</v-icon
                    >
                    <p class="my-0 mx-1">
                      {{ info.createdBy.name }} {{ info.createdBy.lastname }}
                    </p>
                  </span>
                </div>

                <div class="tasks__buttons">
                  <v-tooltip bottom v-if="info.folders">
                    <template v-slot:activator="{ on }">
                      <!-- @click="showFirstAttachments({
                          registryID: info._id,
                          registryName: 'current-informations_details',
                      })"-->
                      <v-btn
                        fab
                        v-on="on"
                        dark
                        :href="paths.attachments + info.folders.fakeFileName"
                        class="mx-2"
                        target="_blank"
                        style="width: 25px; height: 25px"
                        color="#19aa8d"
                      >
                        <v-icon size="12" color="white">mdi-file-search</v-icon>
                      </v-btn>
                    </template>
                    <span>Podgląd</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="getPermission.level >= 3"
                        fab
                        v-on="on"
                        @click="
                          `${$router.push('/current-informations/' + info._id)}`
                        "
                        dark
                        style="width: 25px; height: 25px"
                        color="#674ea7"
                      >
                        <v-icon size="15" color="white"
                          >mdi-arrow-right-thick</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Przejdź do szczegółów</span>
                  </v-tooltip>
                </div>
              </v-alert>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else class="dashboard">
    <!-- <img src="@/assets/img/global/menu-background.jpg" alt="logo Entelli" /> -->
    <v-row>
      <v-col cols="12">
        <!-- <h1>{{header}}</h1> -->
      </v-col>
    </v-row>
    <div style="position: absolute;">
      <img
        style="transform: translate(0%,-50%)"
        :style="` display: ${window.width < 1200 ? 'none' : 'block'}`"
        src="@/assets/img/global/entelli_logo.png"
        alt
      />
    </div>
    <div
      :style="` display: ${window.width < 1200 ? 'none' : 'block'}`"
      class="dashboard_logo_subtitle"
    >
      information driven software
    </div>

    <v-col
      style="min-width: 350px; right: 30px"
      class="p-absolute pa-0"
      cols="12"
      v-if="getUpcomingEvents.length > 0"
      lg="3"
      md="5"
      sm="5"
    >
      <v-card
        class="elevation-20 mr-5"
        style="height: calc(100vh - 64px); padding: 15px; background-color: rgba(255, 255, 255, 0.02); overflow: auto;"
      >
        <p
          class="pa-5 ma-0"
          style="text-align: center; width: 100%; font-size: 23px; letter-spacing: 2px; "
        >
          Informacje
          <span
            style="display: block; width: 100%; border-bottom: 1px solid black; margin-top: 15px"
          ></span>
        </p>
        <div>
          <div v-for="info in getUpcomingEvents" :key="info.index">
            <v-alert
              border="left"
              style="margin-right: 2px"
              colored-border
              v-if="
                info.homePage == true &&
                  info.status.possibleStatus.name == 'Aktualny'
              "
              :color="
                info.priority ? info.priority.background : 'grey lighten-1'
              "
              :key="info.id"
              elevation="2"
              class="pa-5"
            >
              {{ info.length }}
              <div style="color: #674ea7; font-size: 14px" class="m2-4">
                {{ $moment(info.createdAt).format('YYYY-MM-DD HH:mm') }}
              </div>
              <div
                class="mb-2"
                style="padding: 0; color: #674ea7; font-weight: 500;"
              >
                {{ info.title }}
              </div>
              <span
                style="height: 1px; width: 100%; background-color: lightgrey; display: block; margin-bottom: 5px;"
              ></span>
              <div class="tasks__content" style="padding: 10px 0;">
                <p class="ma-0 mb-5" style="color: rgba(0, 0, 0, 0.6)">
                  {{ info.information }}
                </p>
              </div>
              <div style="position: absolute; bottom: 15px; color: grey">
                <span
                  class="d-flex align-center"
                  style="font-size: 11px !important;"
                >
                  <v-icon size="11" style="margin-bottom: 2px"
                    >mdi-account</v-icon
                  >
                  <p class="my-0 mx-1">
                    {{ info.createdBy.name }} {{ info.createdBy.lastname }}
                  </p>
                </span>
              </div>

              <div class="tasks__buttons">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      v-on="on"
                      dark
                      @click="
                        showFirstAttachments({
                          registryID: info._id,
                          registryName: 'current-informations_details',
                        })
                      "
                      class="mx-2"
                      style="width: 25px; height: 25px"
                      color="#19aa8d"
                    >
                      <v-icon size="12" color="white">mdi-file-search</v-icon>
                    </v-btn>
                  </template>
                  <span>Podgląd załącznika</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      v-on="on"
                      @click="
                        `${$router.push('/current-informations/' + info._id)}`
                      "
                      dark
                      style="width: 25px; height: 25px"
                      color="#19aa8d"
                    >
                      <v-icon size="15" color="white"
                        >mdi-arrow-right-thick</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Przejdź do szczegółów</span>
                </v-tooltip>
              </div>
            </v-alert>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import moment from 'moment';
export default {
  data: () => ({
    header: 'Dashboard',
    eventArr: [],
    paths: {
      attachments: `${process.env.VUE_APP_SERVER}/secured/attachments/`,
    },
    window: {
      width: 0,
      height: 0,
    },
  }),
  computed: {
    ...mapGetters(['getLayoutWSD', 'getUpcomingEvents', 'getPermission']),
  },
  methods: {
    ...mapActions(['fetchDashboardCurrentInformation', 'showFirstAttachments']),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  beforeMount() {
    // this.handleResize();
    this.fetchDashboardCurrentInformation();
  },
};
</script>

<style lang="sass"></style>
